import { FlowChartItems } from "../../components/flowChart"

const ChartData: FlowChartItems = [
  {
    collapsedOnInit: true,
    title: `Initial Consultation`,
    routes: [
      {
        nodes: [
          { content: `We discuss your goals & vision` },
          {
            subsection: {
              title: `Plan Status`,
              routes: [
                {
                  nodes: [
                    { content: `You have a plan` },
                    { content: `We review your plan` },
                  ],
                },
                {
                  nodes: [
                    { content: `You don't have a plan` },
                    { content: `We review our plan portfolio` },
                  ],
                },
              ],
            },
          },
          {
            content: `Discussion of timing, budget, financing, & expectations`,
          },
          {
            content: `Overview of our design/build process & project management software`,
          },
          {
            subsection: {
              title: `Land Details 1`,
              routes: [
                {
                  nodes: [
                    { content: `You already own land` },
                    { content: `Review land details (plat, GIS, etc)` },
                    { content: `Schedule site meeting` },
                  ],
                },
                {
                  nodes: [
                    { content: `You don't yet own land` },
                    { content: `We advise on land` },
                    { content: `Optional referal to Sundog Realty agent` },
                  ],
                },
              ],
            },
          },
        ],
      },
    ],
  },
  {
    collapsedOnInit: true,
    title: `Homesite Evaluation`,
    routes: [
      {
        nodes: [
          { content: `We walk your property together` },
          { content: `Discussion of home placement and land usage` },
          { content: `Discussion of unique opportunities and/or challenges` },
          { content: `Review of the available utilities` },
        ],
      },
    ],
  },
  {
    collapsedOnInit: true,
    title: `Planning & Design`,
    routes: [
      {
        nodes: [
          {
            content: `You review and sign our Pre-Construction Services Agreement`,
          },
          {
            content: `We define and obtain quotes for engineering, surveying, site work, etc`,
          },
          {
            subsection: {
              title: `Getting a Plan`,
              routes: [
                {
                  nodes: [
                    { content: `We customize an existing plan together` },
                  ],
                },
                {
                  nodes: [
                    {
                      content: `We work alongside your architectural designer`,
                    },
                  ],
                },
              ],
            },
          },
          {
            content: `We complete architectural drawings and the site plan`,
          },
          {
            content: `Together, we define a list of interior and exterior specifications for budgeting purposes`,
          },
        ],
      },
    ],
  },
  {
    collapsedOnInit: true,
    title: `Estimating/Budgeting`,
    routes: [
      {
        nodes: [
          { content: `We estimate approved architectural plans & site plan` },
          { content: `We obtain quotes from suppliers & subcontractors` },
          {
            content: `We present you with a complete control estimate for your approval`,
          },
          {
            content: `We finalize construction documents & structural engineering`,
          },
          { content: `Together, we execute a contract for construction` },
          {
            content: `You submit an application for a construction loan (if applicable)`,
          },
        ],
      },
    ],
  },

  {
    collapsedOnInit: true,
    title: `Construction`,
    routes: [
      {
        nodes: [
          { content: `We activate your Buildertrend Owner's Portal` },
          { content: `You finalize design selections while we pull permits` },
          {
            content: `You attend a pre-construction orientation with your project manager`,
          },
          {
            content: `We clear and grade your property, then install your home's foundation`,
          },
          {
            content: `We frame and dry-in your home, then install rough mechanicals & insulation`,
          },
          {
            content: `You attend a pre-drywall orientation with your project manager`,
          },
          {
            content: `We hang drywall, paint, and then install finish materials, e.g. countertops flooring, and fixtures`,
          },
          { content: `We perform in-house quality control inspection` },
          { content: `We complete final punch out and cleaning` },
          {
            content: `You attend a pre-settlement orientation (walk-through) with your project manager`,
          },
          { content: `You move in!` },
        ],
      },
    ],
  },
  {
    collapsedOnInit: true,
    isTerminalNode: true,
    title: `2-10 Warranty`,
    routes: [
      {
        nodes: [
          { content: `We setup your warranty access in Buildertrend` },
          { content: `Together, we perform a walk-through on day #30` },
          { content: `Together, we perform a walk-through in month #11` },
        ],
      },
    ],
  },
]

export default ChartData
