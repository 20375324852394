import * as React from "react"
import { Box, BoxProps, Button, Flex, Icon, Text } from "@chakra-ui/react"
import { FaCaretDown } from "react-icons/fa"
import gsap from "gsap"

const BUTTON_COLOR_SCHEME = `red`
const CHART_COLOR = `red.500`
const CONNECTOR_HEIGHT = 50
const LINE_COLOR = `gray.300`
const LINE_STROKE = `4px`
const LINE_STYLE = `dotted`
const NODE_BG_COLOR = `white`
const NODE_BORDER_COLOR = `gray.100`
const NODE_HEIGHT = 100

type Connector = {
  color?: string
  stroke?: string
  issubsection?: boolean
  offsettop?: string | number
}

type TParentConnector = Omit<Connector, `width`> & BoxProps

type TSiblingConnector = Omit<Connector, `height`> & BoxProps

export interface ChartItem {
  content: string
  width: number | string
}

export type NodeProps =
  | {
      content: string
      subsection?: SectionProps
    }
  | {
      content?: string
      subsection: SectionProps
    }

export type RouteProps = {
  nodes: NodeProps[]
}

export type SectionProps = {
  collapsedOnInit?: boolean
  issubsection?: boolean
  isTerminalNode?: boolean
  routes: RouteProps[]
  sectionNumber?: number
  title: string
}

export type FlowChartItems = SectionProps[]

export type FlowChartProps = {
  items: FlowChartItems
}

export type TFlowChart = ChartItem[]

function Item({ content, width }: ChartItem): JSX.Element {
  return (
    <Flex
      align="center"
      bg={NODE_BG_COLOR}
      border="2px solid"
      borderColor={NODE_BORDER_COLOR}
      cursor="default"
      flexDir="column"
      justify="center"
      h={`${NODE_HEIGHT}px`}
      p={2}
      rounded="lg"
      transitionDuration="0.3s"
      w={width}
      _hover={{
        borderWidth: `2px`,
        borderColor: CHART_COLOR,
        shadow: `md`,
        transform: `scale(1.02)`,
      }}
    >
      <Text
        fontSize={[`xs`, `sm`, , `md`]}
        fontWeight="medium"
        textAlign="center"
        w="100%"
      >
        {content}
      </Text>
    </Flex>
  )
}

const defaultConnector: Connector = {
  color: LINE_COLOR,
  issubsection: false,
  offsettop: `-6px`,
  stroke: LINE_STROKE,
}

// standard vertical route
function ParentConnector(props: TParentConnector): JSX.Element {
  const { color, issubsection, stroke } = {
    ...defaultConnector,
    ...props,
  }
  return (
    <Box
      borderLeft={LINE_STYLE}
      borderLeftColor={color}
      borderLeftWidth={stroke}
      d={{ base: issubsection ? `none` : `block`, md: `block` }}
      mx="auto"
      w="0px"
      {...props}
    />
  )
}

// branches 1 route into 2+ routes
function SubsectionConnector(props: TSiblingConnector): JSX.Element {
  const { color, offsettop, stroke } = { ...defaultConnector, ...props }
  return (
    <Box mt={offsettop} w="100%">
      <Box
        borderBottom={LINE_STYLE}
        borderBottomColor={color}
        borderBottomWidth={stroke}
        h="0px"
        mx={{ base: 0, md: `auto` }}
        {...props}
      />
    </Box>
  )
}

// connects vertical route to parallel node
// (mobile subsections only)
function Bridge(): JSX.Element {
  return (
    <Box
      borderTop={LINE_STYLE}
      borderTopColor={LINE_COLOR}
      borderTopWidth={LINE_STROKE}
      d={{ base: `block`, md: `none` }}
      h="0px"
      left="-5%"
      pos="absolute"
      top="calc(50% - 2px)"
      w="6%"
    />
  )
}

// lil helper that cuts down on the repetitiveness of writing
// responsive style props for components that may or may not
// be in a subsection.
function getStyleProp(
  issubsection: boolean,
  ifIs: number | string,
  ifIsNot: number | string
): object {
  return {
    base: issubsection ? ifIs : ifIsNot,
    md: ifIsNot,
  }
}

// recursively render sections and subsections
function Section({
  collapsedOnInit = false,
  issubsection = false,
  isTerminalNode = false,
  sectionNumber,
  routes,
  title,
}: SectionProps): JSX.Element {
  const sectionRef = React.useRef<HTMLDivElement>()
  const [expanded, setExpanded] = React.useState<boolean>(collapsedOnInit)

  const numRoutes = routes.length
  const maxNodes = routes.reduce(
    (acc, cur) => (cur.nodes.length > acc ? cur.nodes.length : acc),
    0
  )
  const maxSubsectionNodes = routes.reduce((acc, cur) => {
    const count = cur.nodes.reduce((_acc, _cur) => {
      if (_cur.content) {
        return _acc
      } else if (_cur.subsection) {
        const len = _cur.subsection.routes.length
        if (len > _acc) {
          return len
        }
      }
      return _acc
    }, 0)
    if (count > acc) {
      return count
    }
    return acc
  }, 0)
  const hasSubsection = maxSubsectionNodes > 0

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  React.useEffect(() => {
    gsap.to(sectionRef.current, {
      duration: expanded ? 0.5 : maxNodes / 10 + 0.2,
      ease: expanded ? `circ.out` : `power2.out`,
      height: expanded ? `0px` : `auto`,
    })
  }, [expanded])

  return (
    <Flex
      align="flex-start"
      bg="gray.50"
      flexDir={getStyleProp(issubsection, `column`, `row`)}
      flexWrap="wrap"
      justify="center"
      overflowY="hidden"
      pb={0}
      px={[2, 4, 4, 4, 6]}
      pos="relative"
      w="100%"
      _first={{ paddingTop: issubsection ? 2 : `24px`, roundedTop: `lg` }}
      _notFirst={{ paddingTop: 1 }}
      _last={{ paddingBottom: `24px`, roundedBottom: `lg` }}
    >
      {!issubsection && (
        <Button
          colorScheme={BUTTON_COLOR_SCHEME}
          leftIcon={
            maxNodes ? (
              <Icon
                as={FaCaretDown}
                bottom="12px"
                left="10px"
                pos="absolute"
                transform={expanded ? `rotate(-90deg)` : `rotate(0deg)`}
                transition="0.3s"
              />
            ) : null
          }
          onClick={toggleExpanded}
          overflowX="hidden"
          pos="relative"
          w="100%"
        >
          Step #{sectionNumber + 1}: {title}
        </Button>
      )}
      {issubsection && (
        <SubsectionConnector width={`calc(50% + ${LINE_STROKE})`} />
      )}
      {routes.map((route: RouteProps, routesIndex: number) => {
        const isLongestRoute = route.nodes.length === maxNodes
        const mdWidth = `${100 / numRoutes}%`

        const longNodeDelta = (maxNodes - route.nodes.length) * NODE_HEIGHT
        const longConnectorDelta = maxNodes * CONNECTOR_HEIGHT
        const longSubsectionNodeHeightMd = NODE_HEIGHT
        const longSubsectionNodeHeightXs = maxSubsectionNodes * NODE_HEIGHT
        const longSubsectionConnectors =
          maxSubsectionNodes * (CONNECTOR_HEIGHT / 4)
        const longSubsectionStrokeMd = hasSubsection ? 16 : 0
        const longSubsectionStrokeXs = hasSubsection ? 20 : 0
        const longConnectorOffsetMd = -NODE_HEIGHT
        const longConnectorOffsetXs = hasSubsection ? -NODE_HEIGHT : 0

        const fixedHeight =
          longNodeDelta + longConnectorDelta + longSubsectionConnectors
        const varHeightMd =
          longSubsectionNodeHeightMd +
          longSubsectionStrokeMd +
          longConnectorOffsetMd
        const varHeightXs =
          longSubsectionNodeHeightXs +
          longSubsectionStrokeXs +
          longConnectorOffsetXs

        const responsiveHeight = {
          base: `${fixedHeight + varHeightXs}px`,
          md: `${fixedHeight + varHeightMd}px`,
        }

        const connectorHeight = issubsection
          ? `${CONNECTOR_HEIGHT / 4}px`
          : isLongestRoute
          ? `${CONNECTOR_HEIGHT}px`
          : responsiveHeight

        return (
          <Flex
            align="center"
            flexDir="column"
            h={issubsection ? `auto` : `0px`}
            justify="start"
            key={routesIndex}
            mt={issubsection ? 0 : `2px`}
            ref={sectionRef}
            w={getStyleProp(issubsection, `100%`, mdWidth)}
          >
            {route.nodes.map((node: NodeProps, nodeIndex: number) => (
              <Box
                borderLeft={getStyleProp(issubsection, LINE_STYLE, `none`)}
                borderLeftColor={getStyleProp(issubsection, LINE_COLOR, null)}
                borderLeftWidth={getStyleProp(issubsection, LINE_STROKE, 0)}
                key={nodeIndex}
                pl={getStyleProp(issubsection, `7%`, 0)}
                pb={getStyleProp(
                  issubsection,
                  nodeIndex === maxNodes - 1 ? 2 : 0,
                  0
                )}
                pt={getStyleProp(issubsection, nodeIndex === 0 ? 2 : 0, 0)}
                w="100%"
              >
                {node.subsection ? (
                  <>
                    <Section issubsection={true} {...node.subsection} />
                    <Box ml={{ base: `6%`, md: 0 }}>
                      <SubsectionConnector
                        offsettop="4px"
                        width={`calc(45% + ${LINE_STROKE} + 2px)`}
                      />
                    </Box>
                    <ParentConnector h={connectorHeight} />
                  </>
                ) : (
                  <Box pos="relative" px={[1, 2]} w="100%">
                    {issubsection && (
                      <ParentConnector
                        h={connectorHeight}
                        issubsection={issubsection}
                      />
                    )}
                    {!issubsection && nodeIndex === 0 && !isTerminalNode && (
                      <ParentConnector h={`${CONNECTOR_HEIGHT / 2}px`} />
                    )}
                    {isTerminalNode && nodeIndex === 0 && (
                      <ParentConnector
                        h={`${CONNECTOR_HEIGHT / 2}px`}
                        opacity={expanded ? `0` : `1`}
                        transition="0.5s"
                      />
                    )}
                    <Item content={node.content} width="100%" />
                    {(nodeIndex + 1 < maxNodes || !isTerminalNode) && (
                      <ParentConnector
                        h={connectorHeight}
                        issubsection={
                          issubsection && nodeIndex === maxNodes - 1
                        }
                      />
                    )}
                    {issubsection &&
                      (nodeIndex === 0 || nodeIndex === maxNodes - 1) && (
                        <Bridge />
                      )}
                  </Box>
                )}
              </Box>
            ))}
          </Flex>
        )
      })}
    </Flex>
  )
}

function FlowChart({ items }: FlowChartProps): JSX.Element {
  return (
    <Box w="100%">
      <Flex align="center" flexDir="column" justify="center" mb={24} w="100%">
        {items.map((section, key) => (
          <Section key={key} sectionNumber={key} {...section} />
        ))}
      </Flex>
    </Box>
  )
}

export default FlowChart
