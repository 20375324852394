import { Box, BoxProps, Heading, Text } from "@chakra-ui/react"
import * as React from "react"
import { PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import FlowChart from "../components/flowChart"
import ChartData from "../data/flowCharts/workingWithSundog"
import BlockHeader from "../components/blockHeader"
import FAQVideo from "../components/faqVideo"
import SEO from "../components/CoreUI/SEO"

function UniformBox(props: BoxProps): JSX.Element {
  return <Box maxW="95vw" mx="auto" px={4} py={1} w="800px" {...props} />
}

function WorkingWithSundog({ location }: PageProps): JSX.Element {
  return (
    <Layout>
      <SEO
        meta={{
          description:
            "What are the steps in building a new home? We outline the entire process step-by-step, so it’s not so much of a mystery.",
          image: `${location.origin}/sundog-design-studio-client-1.jpg`,
          imageAlt: `A client making selections in Studio Sundog`,
        }}
        title="Working With The Sundog Team | Sundog Homes"
        url={location.href}
      />
      <Box h={[`25vh`, `35vh`]} w="100vw">
        <StaticImage
          alt="The interior of a Sundog-built home in Cullowhee, NC."
          className="squared"
          src="../images/working-with-sundog.jpg"
          objectFit="cover"
          style={{ height: `100%`, width: `100%` }}
        />
      </Box>
      <UniformBox mt={12} textAlign="center">
        <BlockHeader mx="auto" w="250px">
          Working With Sundog
        </BlockHeader>
        <Text
          fontFamily="heading"
          fontSize={[`3xl`, `5xl`]}
          fontWeight="bold"
          mt={6}
        >
          Homebuilding made simple.
        </Text>
        <Text fontSize="lg" fontWeight="medium" mb={12} mt={4} textAlign="left">
          ​​The best things in life require time, energy, and focus. Building a
          custom home is no different. Whether you have a plan or are still
          looking for one, we chart the course and help you enjoy every step of
          the process.
        </Text>
      </UniformBox>
      <UniformBox pb={16}>
        <FAQVideo
          question="Does Sundog refer design/build clients to outside architects?"
          oEmbedUrl="https://sundoghrv.wistia.com/medias/j5mbfvqy8y?embedType=iframe&videoFoam=true&videoWidth=640"
          videoDescription="Phillip & David discuss the value of integrating a design/build team."
        />
      </UniformBox>
      <UniformBox>
        <FlowChart items={ChartData} />
      </UniformBox>
    </Layout>
  )
}

export default WorkingWithSundog
